
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.animated-image {
  width: 20%;
  height: auto;
  animation: scaleAnimation 5s infinite ease-in-out;
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
